import axios from 'axios'
import { useMenuStore } from '/stores/menues'
import { useFormStore } from '/stores/forms'
import { useMainContentStore } from '/stores/mainContent'

class SaveData {
    constructor () {
        this.baseUrl = 'https://administration.w-s.app/api'
    }

    async fillMenuStore (endpoint) {
        const menuStore = useMenuStore()
        const url = this.baseUrl + endpoint

        axios.get(url)
            .then(function (response) {
                const mainMenuData = response.data.menues.mainmenu
                const serviceMenuData = response.data.menues.servicemenu

                menuStore.setMainMenu(mainMenuData)
                menuStore.setServiceMenu(serviceMenuData)
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    async fillFormStore (endpoint) {
        const formStore = useFormStore()
        const url = this.baseUrl + endpoint

        await axios.get(url)
            .then(function (response) {
                const formID = response.data.formID
                const formObject = response.data.children
                const formArray = Object.entries(formObject)

                const completeFormObj = {
                formID: formID,
                form: formArray
                }

                formStore.pushForm(completeFormObj)
                formStore.setLoaded()
            })
            .catch(function (error) {
                console.log(error)
            })
    }
}

export default SaveData
