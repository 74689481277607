
<template>
    <div class="cookie-banner__outer-box row flex-center">
        <div class="cookie-banner__box modal-content col-11 col-lg-10">
            <div class="cookie-banner">
                <div class="row content-row">
                    <h2 class="col-12">{{ content.cookieHeadline }}</h2>

                    <div class="col-12 col-lg-10 cookie-banner__text" v-html="content.cookieText"></div>
                </div>

                <div class="row justify-between settings-row">
                    <div class="col-auto col-md-12 cookie-banner__options">
                        <div class="round-checkbox">
                            <label for="essential-cookies-checkbox">
                            <input 
                                type="checkbox"
                                id="essential-cookies-checkbox" 
                                v-model="essentialCookies" 
                                :disabled="true"
                                checked="checked"
                            />
                                {{ content.cookieEssential }}
                            </label>
                        </div>
    
                        <div class="round-checkbox">
                            <label for="marketing-cookies-checkbox">
                            <input 
                                type="checkbox"
                                id="marketing-cookies-checkbox"
                                :checked="marketingCheck === 'true'"
                                v-model="marketingCookies"
                            />
                                {{ content.cookieFunctional }}
                            </label>
                        </div>
    
                        <div class="round-checkbox">
                            <label for="analytics-cookies-checkbox">
                            <input 
                                type="checkbox"
                                id="analytics-cookies-checkbox"
                                :checked="analyticsCheck === 'true'"
                                v-model="analyticsCookies" 
                            />
                                {{ content.cookieAnalytics }}
                            </label>
                        </div>
                    </div>

                    <div class="col-auto col-md-12 text-right cookie-banner__buttons">
                        <button @click="toggleCookieBanner(false); cookie.acceptCookies()" class="btn btn-secondary accept">
                            {{ content.cookieAccept }}
                        </button>

                        <button @click="cookie.resetCookies()" class="reset-btn btn">
                            {{ content.resetCookies }}
                        </button>
                    </div>
                </div>
            </div>

            <button
                class="c-button c-button--close"
                @click="toggleCookieBanner(false)"
            ></button>
        </div>
    </div>
</template>

<script setup>
    import { CookieHandling } from '@/assets/js/cookieConsent'
    import content from "~/content/cookie"

    const emit = defineEmits([
        'toggleCookieBanner'
    ])

    const toggleCookieBanner = (value) => {
        emit('toggleCookieBanner', value)
    }

    const cookie = new CookieHandling();

    const analyticsCheck = ref(cookie.getCookie('analyticsCookies'));
    const marketingCheck = ref(cookie.getCookie('marketingCookies'));
</script>