<template>
    <menu
        :class="{
            'c-side-menu': props.menuType === 'sideMenu',
            'c-main-menu': props.menuType === 'mainMenu'
        }"
    >
        <li class="menu__item">
            <NuxtLink
                :to="'/'"
                class="menu__link"
                :class="{'btn': props.menuType === 'sideMenu'}"
                @click.native="generalStore.deleteSubPage(); contentStore.deleteSubPageContent()"
            > 
                Home
            </NuxtLink>
        </li>

        <li
            v-for="menuItem in mainMenu" 
            :key="menuItem.id"
            class="menu__item"
        >
            <NuxtLink 
                :to="menuItem.submenu.length > 0 ? `/${menuItem.submenu[0].route}/` : `/${menuItem.route}/`"
                class="menu__link"
                :class="{
                    'btn': props.menuType === 'sideMenu',
                    'router-link-active': route.params.secondlevel === menuItem.route
                }"
                @click.native="generalStore.deleteSubPage(); contentStore.deleteSubPageContent()"
            >
                {{ menuItem.title }}
            </NuxtLink>
        </li>
    </menu>
</template>

<script setup>
    import { useMenuStore } from "@/stores/menues"
    import { useGeneralStore } from "@/stores/general"
    import { useMainContentStore } from "@/stores/mainContent"

    const route = useRoute()

    const menuStore = useMenuStore()
    const generalStore = useGeneralStore()
    const contentStore = useMainContentStore()
    const { mainMenu } = storeToRefs(menuStore)

    const props = defineProps({
        menuType: String
    })
</script>
