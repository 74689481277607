<template>
    <div class="page-layout">
        <OffcanvasTransition>
            <SideNavigation 
                v-if="isSidenavLayout || isMobile" 
                :mobileView="isMobile"
                @toggleLoginModal="(value) => (openModal = value, modalContent = 'login')"
            />
        </OffcanvasTransition>
        
        <div 
            class="q-layout q-layout--standard"
            :class="isSidenavLayout || isMobile ? 'sidenav-opened' : ''"
        >       
            <PageHeader 
                :showTitle="true" 
                :mobileView="isMobile" 
                @toggleLoginModal="(value) => (openModal = value, modalContent = 'login')"
            />
            
            <main :class="isSidenavLayout ? 'sidenav-content' : 'no-sidenav-content'">
                <slot />
            </main>

            <FooterTransition>
                <PageFooter @toggleCookieBanner="(value) => (openCookie = value)" />
            </FooterTransition>
        </div>

        <!-- <DownloadArea v-if="files.length > 0" /> -->
        <DownloadArea />

        <SlideLeftTransition>
            <div class="contact__trigger">
                <button @click="openModal = !openModal, modalContent = 'contactForm'" class="btn btn-secondary btn-circle btn-circle--small">
                    <i class="icon-kontakt" />
                </button>
            </div>
        </SlideLeftTransition>

        <ModalTransition>
            <PageModal v-if="openModal" @toggleModal="(value) => (openModal = value)" :content="modalContent" />
        </ModalTransition>

        <ModalBackgroundTransition>
            <div class="modal-background" v-if="openModal"></div>
        </ModalBackgroundTransition>

        <ModalTransition>
            <CookieBanner
              v-if="openCookie"
              @toggleCookieBanner="(value) => (openCookie = value)"
            />
        </ModalTransition>
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router'
    import SaveData from 'assets/js/getData.cls'
    import { useDownloadStore } from '/stores/download'
    import { useMenuStore } from '/stores/menues'
    import { useGeneralStore } from '/stores/general'
    import { CookieHandling } from '@/assets/js/cookieConsent'
    import { useMainContentStore } from '/stores/mainContent'

    const menuStore = useMenuStore()
    const downloadStore = useDownloadStore()
    const contentStore = useMainContentStore()
    const generalStore = useGeneralStore()
    const { mainMenu } = storeToRefs(menuStore)
    const { files } = storeToRefs(downloadStore)
    const { currentPageID, parentPageID } = storeToRefs(generalStore)

    // check if user is logged in
    const { $acquireTokenSilent } = useNuxtApp()

    if (await $acquireTokenSilent()) {
        generalStore.toggleLoggedIn(true)
    }


    // setting variable for drawer-switch
    const isMobile = ref(false)
    const openModal = ref(false)
    const modalContent = ref('contactForm')
    const openCookie = ref(true)
    const isSidenavLayout = ref(false)

    // handle routing
    const route = useRoute()

    // hydrate stores
    const data = new SaveData()

    data.fillFormStore('/formdata/1')

    // cookie handling
    const cookie = new CookieHandling(generalStore);

    if (cookie.getCookie("showCookieConsent") === "true") {
        openCookie.value = true;
    } else {
        openCookie.value = false;
    }

    const parentID = ref('')
    const processID = ref('')

    const setProcessID = () => {
        if (route.params.slug) {
            const currentParent = mainMenu.value.filter(menuPoint => menuPoint.route === route.params.secondlevel)
            parentID.value = currentParent[0].processID
            generalStore.setParentPageID(currentParent[0].processID)

            const currentNavPoint = currentParent[0].submenu.filter(subMenuPoint => subMenuPoint.route === `${route.params.secondlevel}/${route.params.slug}`)
            processID.value = currentNavPoint[0].processID
            generalStore.setProcessID(currentNavPoint[0].processID)
        } else {
            const currentNavPoint = mainMenu.value.filter(menuPoint => menuPoint.route === route.params.secondlevel)
            parentID.value = ''
            
            if (currentNavPoint.length > 0) {
                processID.value = currentNavPoint[0].processID
                
                generalStore.setParentPageID(null)
                generalStore.setProcessID(currentNavPoint[0].processID)
            }
        }
    }

    watch ([route, mainMenu], () => {
        generalStore.setParentPageID(null)

        if (route.path !== '/' && !route.path.includes('services/') ) {
            setProcessID()
            isSidenavLayout.value = true
    
            if (parentPageID.value !== null) {
                contentStore.createPageContent('de', parentPageID.value)
            } else if (currentPageID.value !== null) {
                contentStore.createPageContent('de', currentPageID.value)
            }   
        } else {
            isSidenavLayout.value = false
        }
    })

    // resize/responsive handling
    const resizeObserver = new ResizeObserver((entry) => {
        const contentBreakpoint = 1500

        let windowWidth = document.body.getBoundingClientRect().width;

        if (windowWidth < contentBreakpoint) {
            if (document.querySelector('.sidenav__container')) {
                const contentContainer = document.querySelector('.q-layout')

                contentContainer.classList.add('mobile')
            }
            
            isMobile.value = true
        } else {
            const contentContainer = document.querySelector('.q-layout')

            contentContainer.classList.remove('mobile')

            isMobile.value = false
        }
    })

    onMounted(() => {
        resizeObserver.observe(document.body);
    })
</script>
