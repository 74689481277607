<template>
    <h3>CD Guidelines</h3>

    <div class="login__button" @click="openLoginModal(true)">
        <span v-if="!loggedIn">Anmelden</span>
        <span v-if="loggedIn">Abmelden</span>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "@/stores/general"

    const emit = defineEmits([
        'toggleLoginModal'
    ])

    const openLoginModal = (value) => {
        emit('toggleLoginModal', value)
    }

    const generalStore = useGeneralStore()
    const { loggedIn } = storeToRefs(generalStore)
</script>