<template>
    <footer class="c-footer c-layout-container" :class="footerBackgroundColor">
      <div class="row justify-between c-company-container">
        <div class="col-12 col-sm-auto q-py-lg">
          <address>
            <a :href="'mailto:' + companyData.company_mail">
              {{ companyData.company_mail }} </a
            ><br />
  
            <a :href="'tel:' + formattedTel">
              {{ companyData.company_phone }} </a
            ><br />
  
            <br />
            {{ companyData.company_name }}<br />
            {{ companyData.company_address }}<br />
            {{ companyData.company_postcode }} {{ companyData.company_city }}
          </address>
        </div>
  
        <div class="col-4 col-sm-auto c-logo-container">
          <PageLogo :logoColor="'white'"/>
        </div>
      </div>
  
      <div class="row justify-between" style="align-items: baseline">
        <div class="col-auto">
          <FooterNavigation @toggleCookieBanner="toggleCookieBanner(true)" />
        </div>
  
        <div class="col-auto pt-4">
          <span>
            <strong>{{ year }} &#169; {{ companyData.company_name }}</strong>
          </span>
        </div>
      </div>
    </footer>
  </template>
  
<script setup>
    import { useGeneralStore } from "/stores/general";
    import { useMainContentStore } from "/stores/mainContent";
    
    const store = useGeneralStore();
    const contentStore = useMainContentStore();
    const { companyData } = storeToRefs(store);
    const { pageContent } = storeToRefs(contentStore);
    
    const footerBackgroundColor = ref("");
    
    store.createCompanyData('de');

    const formattedTel = ref('')
    
    watch(companyData, () => {
        let tel = companyData.value.company_phone
        tel = tel.replace(' ', '')
        tel = tel.replace('.', '')
        tel = tel.replace('-', '')

        formattedTel.value = tel
    })

    const emit = defineEmits(["toggleCookieBanner"]);

    const toggleCookieBanner = (value) => {
        emit("toggleCookieBanner", value);
    };
    
    // functionality for current year
    let year = ref(null); 
    
    const currentYear = () => {
        const current = new Date();
        year.value = current.getFullYear();
    };
    
    currentYear();
</script>
  