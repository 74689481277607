<template>
    <div class="search__container">
        <div class="btn search__trigger" @click="triggerSearch($event)">
            Suche
        </div>

        <div class="search__input">
            <input class="search__field" @focusout="closeSearch()">

            <div class="btn btn-secondary btn-circle">
                <i class="icon-pfeil" />
            </div>
        </div>
    </div>
</template>

<script setup>
    const triggerSearch = (event) => {
        const searchContainer = event.target.parentElement
        const searchInput = searchContainer.querySelector('.search__field')

        searchContainer.classList.add('active')
        searchInput.focus()
    }

    const closeSearch = () => {
        const searchContainer = document.querySelector('.search__container')

        searchContainer.classList.remove('active')
    }

</script>
