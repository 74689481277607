<template>
  <div class="page__modal">
    <div class="page__modal-container modal-content">
      <div @click="toggleModal(false)" class="contact__close-button btn btn-secondary btn-circle-close"></div>
        <FormWidget v-if="props.content === 'contactForm'" :formID="1" />
        <LoginWidget v-if="props.content === 'login'" @toggleModal="(value) => toggleModal(value)" />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    content: String
  })

  const emit = defineEmits(['toggleModal'])

  const toggleModal = (value) => {
    emit('toggleModal', value)
  }
</script>
