<template>
    <div>
        <div class="login-container">
            <div class="login-info">
                <h3>{{ content.loginHeadline }}</h3>

                <p>{{ content.loginText }}</p>
            </div>
        </div>

        <div
            v-if="!loggedIn"
            class="submit__container"
        >
            <button 
                class="btn btn-primary btn-arrow" 
                @click="login(), toggleModal(false)"
            >
                {{ content.loginButton }}
            </button>
        </div>

        <div v-if="loggedIn" class="submit__container">
            <button 
                class="btn btn-primary btn-arrow" 
                @click="logout(), toggleModal(false)"
            >
                {{ content.logoutButton }}
            </button>
        </div>
    </div>
</template>

<script setup>
    import content from "@/content/modals"
    import { useGeneralStore } from "@/stores/general"

    const emit = defineEmits(['toggleModal'])

    const toggleModal = (value) => {
        emit('toggleModal', value)
    }

    const generalStore = useGeneralStore()
    const { loggedIn } = storeToRefs(generalStore)

    const { $login, $logout, $acquireTokenSilent } = useNuxtApp()

    const login = async() => {
        const loginResponse = await $login()

        if(loginResponse) {
            generalStore.toggleLoggedIn(true)
        }
    }

    const logout = async() => {
        await $logout()
        generalStore.toggleLoggedIn(false)
    }
</script>