<template>
    <NuxtLink :to="'/'">
        <div 
            class="c-logo"
            :class="props.logoColor === 'white' ? 'white-logo' : ''"
            v-html="logo"
        ></div>
    </NuxtLink>
</template>

<script setup>
    import logo from '~/assets/img/ws-logo-basic.svg'

    const props = defineProps({
        logoColor: String
    })
</script>