<template>
    <TransitionGroup name="menu-transition">
        <slot v-if="show"></slot>
    </TransitionGroup>
</template>

<script setup>
    const show = ref(false)

    onMounted(() => {
        if (show) show.value = true
    })
</script>