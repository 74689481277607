
class CookieHandling {
    constructor(store) {
        this.store = store
    }

    setCookie = (cookieName, cookieValue, exDays = 30) => {
        const day = new Date();
        day.setTime(day.getTime() + (exDays*24*60*60*1000));
        let expires = "expires=" + day.toUTCString();

        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    getCookie = (cookieName) => {
        let match = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));

        if(match === null) {
            return false
        } else {
            return match[2];
        }
    }

    deleteCookie = (cookieName) => {
        const expires = 'expires=Thu, 01-Jan-1970 00:00:01 GMT';

        document.cookie = cookieName + '=;' + expires + ';path=/';
    }

    acceptCookies = () => {
        const analyticsCheckbox = document.querySelector('#analytics-cookies-checkbox');
        const marketingCheckbox = document.querySelector('#marketing-cookies-checkbox');

        if(analyticsCheckbox.checked === true) {
            this.setCookie('analyticsCookies', true);
        } else {
            this.deleteCookie('analyticsCookies')
        }

        if(marketingCheckbox.checked === true) {
            this.setCookie('marketingCookies', true);
        } else {
            this.deleteCookie('marketingCookies')
        }

        this.setCookie('showCookieConsent', false);
        this.setCookie('essentialCookies', true);

        analyticsCheckbox.disabled = true;
        marketingCheckbox.disabled = true;
    }

    resetCookies = () => {
        const analyticsCheckbox = document.querySelector('#analytics-cookies-checkbox');
        const marketingCheckbox = document.querySelector('#marketing-cookies-checkbox');

        // remove cookies or reset them to default values
        analyticsCheckbox.checked = false;
        marketingCheckbox.checked = false;

        this.deleteCookie('analyticsCookies');
        this.deleteCookie('marketingCookies');
    }
}

export { CookieHandling }