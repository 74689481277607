<template>
    <menu class="c-service-menu">
        <li
            v-for="menuItem in serviceMenu" 
            :key="menuItem.id"
            class="menu__item"
        >
            <NuxtLink 
                :to="`/services/${menuItem.route}`"
                class="menu__link"
                @click.native="generalStore.deleteSubPage()"
            >
                {{ menuItem.title }}
            </NuxtLink>
        </li>

        <li class="menu__item">
            <a
                class="menu__link"
                @click="toggleCookieBanner(true)"
            >
                Cookie Einstellungen
            </a>
        </li>
    </menu>
</template>

<script setup>
    import { useMenuStore } from "@/stores/menues"
    import { useGeneralStore } from "@/stores/general";

    const emit = defineEmits([
        'toggleCookieBanner'
    ])

    const toggleCookieBanner = (value) => {
        emit('toggleCookieBanner', value)
    }

    const menuStore = useMenuStore();
    const generalStore = useGeneralStore();
    const { serviceMenu } = storeToRefs(menuStore);
</script>
