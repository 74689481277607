<template>
    <header class="header__container">
        <SlideDownTransition>
            <div class="header__container-inner">
                <div 
                    v-if="!props.mobileView"
                    class="page__title header__title" 
                >
                    <HeaderTitle v-if="props.showTitle" @toggleLoginModal="(value) => openLoginModal(value)" />
                </div>
        
                <div
                    v-if="!props.mobileView"
                    class="header__navigation" 
                >
                    <MenuNavigation :menuType="'mainMenu'" />
                </div>
        
                <div 
                    v-if="!props.mobileView"
                    class="page__search"
                >
                    <PageSearch />
                </div>
        
                <div class="header__logo">
                    <PageLogo :logoPosition="'header'" />
                </div>
            </div>
        </SlideDownTransition>
    </header>
</template>

<script setup>
    import { useMenuStore } from '/stores/menues'

    const props = defineProps({
        showTitle: Boolean,
        mobileView: Boolean
    })

    const emit = defineEmits([
        'toggleLoginModal'
    ])

    const openLoginModal = (value) => {
        emit('toggleLoginModal', value)
    }

    const processID = '1228'
    const locale = 'de'

    const menuStore = useMenuStore()

    menuStore.setMenues(locale, processID)

    document.addEventListener('scroll', () => {
        const header = document.querySelector('.header__container')

        if (window.scrollY > 50) {
            header.classList.add('small-header')
        } else {
            header.classList.remove('small-header')
        }
    })
</script>
